// native
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, ErrorHandler } from '@angular/core';

// addon
import { TrackJS } from 'trackjs';

// module
import { SmartciteSharedModule } from '@readcube/smartcite-shared';

// service
import { AppErrorHandler } from './app-error-handler';

// component
import { AppComponent } from './app.component';

// environment
import { environment } from 'src/environments/environment';

// build
import { BUILD_VERSION } from 'build/build-version';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    SmartciteSharedModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor() {
    TrackJS.install({
      token: environment.trackJsToken,
      application: environment.trackJsApp,
      enabled: environment.trackJsEnabled,
      version: BUILD_VERSION
    });
  }
}
