
import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { ConfigService } from './services/config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {

  constructor(
    public configService: ConfigService
  ) { }

  ngOnInit() {
    this.configService.initDocumentSelectionPing();
  }
}
