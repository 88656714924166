// native
import { ErrorHandler, Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

// addon
import { TrackJS } from 'trackjs';

// service
import { UserService } from '@readcube/smartcite-shared';

// environament
import { environment } from 'src/environments/environment';

@Injectable()
export class AppErrorHandler implements ErrorHandler {

  constructor(
    private userService: UserService
  ) { }

  handleError(error: any) {
    if (!environment.trackJsEnabled) {
      throw error;
    }

    console.error(error.originalError || error);

    this.userService.user$.pipe(first()).subscribe((user) => {
      TrackJS.track({
        error: error.originalError || error,
        userId: user?.id,
        userEmail: user?.email,
        client: environment.clientName
      });
    }, () => {
      TrackJS.track({
        error: error.originalError || error,
        client: environment.clientName
      });
    });
  }
}