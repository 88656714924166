export const environment = {
  name: 'staging',
  production: false,
  baseUrls: {
    papers: '//papersapp.com',
    readcube: '//www.readcube.com',
    app: '//app.readcube.com',
    support: '//support.readcube.com',
    sync: 'https://sync.readcube.com',
    services: 'https://services.readcube.com',
    smartciteAssets: 'https://smartcite-assets.readcube.com',
    lambda: 'https://api-gateway.readcube.com',
    webapp: 'https://app.readcube.com',
    smartciteApp: 'https://smartcite-docs-staging.readcube.com'
  },
  clientName: 'smartcite_docs',
  trackJsApp: 'smartcite-docs-staging',
  trackJsToken: '4587af51bbb542afbbc9d93bf9567159',
  trackJsEnabled: true
};