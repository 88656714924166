const itemAsAuthorYearPair = (item: any) => {

  let firstAuthorSurname =
    item.article.authors?.[0]?.split(' ')?.pop() || 'No author';

  if (firstAuthorSurname?.length && firstAuthorSurname[firstAuthorSurname.length - 1] === '~')
    firstAuthorSurname = firstAuthorSurname.slice(0, -1);

  const year =
    item.article.year || getYearFromMetadata(item.custom_metadata?.date) || 'No year';

  return `${firstAuthorSurname} ${year}`;
};

const getYearFromMetadata = (val) => {
  if (!val)
    return;

  if (/^\d{1,4}$/.test(val) && val > 0)
    return val;

  let dateParts = /^(\d{1,4})-(\d{1,2})$/.exec(val);

  const hasYearMonth =
    dateParts
    && dateParts[1] && (parseInt(dateParts[1]) > 0)
    && dateParts[2] && (parseInt(dateParts[2]) > 0);

  if (hasYearMonth)
    return dateParts[1];

  dateParts = /^(\d{1,4})-(\d{1,2})-(\d{1,2})$/.exec(val);

  const hasYearMonthDate =
    dateParts
    && dateParts[1] && (parseInt(dateParts[1]) > 0)
    && dateParts[2] && (parseInt(dateParts[2]) > 0)
    && dateParts[3] && (parseInt(dateParts[3]) > 0);

  if (hasYearMonthDate)
    return dateParts[1];
};

export const itemsAsDefaultCitationText = (items: any) => {

  const itemsText =
    items
      .map(itemAsAuthorYearPair)
      .join(',');

  return `(${itemsText})`;
};

export const itemsAsRefs = (items: any[]) =>
  items
    .map(i => i.collection_id + ':' + i.id);
